import React from 'react'
import PropTypes from 'prop-types'
import { ArticleConsumer } from './ArticleContext'

const Article = ({ articleKey, children }) => (
  <ArticleConsumer>
    {({ onCloseArticle, article, articleTimeout }) => (
      <article id={articleKey} className={`${articleKey === article ? 'active' : ''} ${articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
        {children}
        <div className="close" onClick={() => onCloseArticle()} />
      </article>
    )}
  </ArticleConsumer>
)

Article.propTypes = {
  articleKey: PropTypes.string.isRequired,
}

export default Article