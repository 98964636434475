import React from 'react'
import PropTypes from 'prop-types'

import IntroArticle from './articles/IntroArticle'
import WorkArticle from './articles/WorkArticle'
import OpenSourceArticle from './articles/OpenSourceArticle'
import AboutArticle from './articles/AboutArticle'
import ContactArticle from './articles/ContactArticle'

const Main = ({ setWrapperRef, timeout }) => (
  <div ref={setWrapperRef} id="main" style={timeout ? {display: 'flex'} : {display: 'none'}}>
    <IntroArticle />
    <WorkArticle />
    <OpenSourceArticle />
    <AboutArticle />
    <ContactArticle />
  </div>
)

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main