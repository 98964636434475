import React, { useState } from 'react'
import PropTypes from 'prop-types'

const _loaded = {};

const ImageLoader = ({ src, className, loadedClassName, loadingClassName, ...props }) => {
  const [loaded, setLoaded] = useState(_loaded[src]);
  const onLoad = () => {
    _loaded[src] = true;
    setLoaded(true);
  };

  className = `${className} ${loaded
    ? loadedClassName
    : loadingClassName}`;

  return <img
    {...props}
    src={src}
    className={className}
    onLoad={onLoad} />;
};
ImageLoader.propTypes = {
  src: PropTypes.string.isRequired,
}
ImageLoader.defaulProps = {
  className: "",
  loadingClassName: "img-loading",
  loadedClassName: "img-loaded"
}

export default ImageLoader;
