import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ timeout, onOpenArticle }) => (
    <header id="header" style={timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-diamond" />
        </div>
        <div className="content">
            <div className="inner">
                <h1>Welcome to Pirokiko dev</h1>
                <p>
                  This is my site, where you can find info about work i have done, things i'm working on and what i'm trying out.
                </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="#article-intro" onClick={() => {onOpenArticle('intro')}}>Intro</a></li>
                <li><a href="#article-work" onClick={() => {onOpenArticle('work')}}>Work</a></li>
                <li><a href="#article-open-source" onClick={() => {onOpenArticle('open-source')}}>Open source</a></li>
                <li><a href="#article-about" onClick={() => {onOpenArticle('about')}}>About</a></li>
                <li><a href="#article-contact" onClick={() => {onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
