import React from 'react'
import Article from '../Article'
import contactImg from '../../images/contact.jpg'

const ContactArticle = () => (
  <Article articleKey="contact">
    <h2 className="major">Contact</h2>
    <form method="post" action="#">
      <div className="field half first">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />
      </div>
      <div className="field half">
        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" />
      </div>
      <div className="field">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="4" />
      </div>
      <ul className="actions">
        <li><input type="submit" value="Send Message" className="special" /></li>
        <li><input type="reset" value="Reset" /></li>
      </ul>
    </form>
    <ul className="icons" style={{marginBottom: 0}}>
      <li><a href="#social-twitter" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
      <li><a href="#social-facebook" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
      <li><a href="#social-instagram" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
      <li><a href="#code-github" className="icon fa-github"><span className="label">GitHub</span></a></li>
    </ul>
  </Article>
);
export default ContactArticle;