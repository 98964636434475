import React from 'react'
import LazyLoad from 'react-lazy-load'
import introImage from '../../images/intro.jpg'
import Article from '../Article'
import ImageLoader from '../ImageLoader'

const IntroArticle = () => (
  <Article articleKey={'intro'}>
    <h2 className="major">Intro</h2>
    <span className="image main">
      <LazyLoad
        debounce={false}
        offsetVertical={500}
      >
        <ImageLoader src={introImage} alt="" />
      </LazyLoad>
    </span>
    <p>Hello, i'm a full-stack developer, working often on both backend and frontend, using a myriad of different technologies to get the best result.</p>
    <h3>Technologies</h3>
    <p>Often one or two technologies aren't enough to give the proper user experience. Generally i use a mixture of these technoglogies if applicable.</p>
    <h4>PHP & Symfony</h4>
    <p>My basic staple for backend related work. PHP is one of the languages i have used the most in the past few years. The symfony framework gives a solid basis to work on and to keep using best practices, to properly seperate code.</p>
    <h4>ES6+, React & Webpack</h4>
    <p>My new basic setup for frontend related work. Working with ES6+ features really cleans up some code to be much more readable. React allows for better reuseabillity for parts of the site to keep everything uniform and is very performant for the end user. Webpack is my standard bundler for the JS code (using Symfony Encore for a good basic setup), to have possibilities for code splitting so users don't download code they won't use, tree shaking to only include what is used instead of always the full package and integrates nicely in the backend thanks to Symfony Encore.</p>
    <h4>GraphQL</h4>
    <p>The latest development for building API's. With proper integration in React through React Apollo and decent support in a couple of Symfony bundles, i've come to really like it. It addresses most reservations i had with REST, though it adds a bit more verbosity currently on the backend. The initial setup and updating the schema for new fields can be a bit more cumbersome than for REST, i currently favor it over REST.</p>
    <h4>WebSockets</h4>
    <p>Sometimes your work requires near-instant communication, this is when WebSockets com into play, sending the data to any listening client as soon as the server knows about it.</p>
    <h4>Redis</h4>
    <p>Redis is great to use as a cache mechanism and certainly helps speed up your site greatly if you have data intensive projects, where resources can be cached.</p>
    <h4>RabbitMQ</h4>
    <p>RabbitMQ is one of several popular message queue systems. It allows to schedule work to be done and a worker will pick it up and start working on it. This is a great way to offload expensive operations, which would otherwise let the user wait untill it is clear something is happening, but now you can immediatly tell the customer that the work is being performed and they will be updated when it is finished.</p>
  </Article>
)

export default IntroArticle